import { DropdownProps } from 'components/atoms/Dropdown/Dropdown.model';

enum Model {
  PAAS = 'Paas',
  IAAS = 'Iaas',
  CAAS = 'Caas',
}

export const dropdownAzure: DropdownProps = {
  header: {
    icon: 'azure-logo',
    label: 'Azure',
    iconCategory: 'supportedComponentsPlatform',
  },
  items: [
    {
      icon: 'functions',
      label: 'Functions',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'app_service',
      label: 'App Service',
      subLabel: 'Paas',
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'Service_Bus',
      label: 'Service Bus',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'CosmosDB',
      label: 'Cosmos DB',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'Web_Apps',
      label: 'Web Apps',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'Storage_Accounts',
      label: 'Storage Accounts',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'Firewall',
      label: 'Azure Firewall',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'Azure_VM',
      label: 'VM',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'AKS',
      label: 'AKS',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'Relay',
      label: 'Relay',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'CDN',
      label: 'CDN',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'Azure_DNS',
      label: 'DNS',
      subLabel: Model.IAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'GremlinDB',
      label: 'Gremlin DB',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'Cassandra',
      label: 'Cassandra',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'File_Shares',
      label: 'File Shares',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'PostgreSQL',
      iconName: 'PostgreSQL_name',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'VNets',
      label: 'VNets',
      subLabel: Model.IAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'Key_Vault',
      label: 'Key Vault',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
  ],
};

export const dropdownGCP: DropdownProps = {
  header: {
    icon: 'gcp-logo',
    label: 'GCP',
    iconCategory: 'supportedComponentsPlatform',
  },
  items: [
    {
      icon: 'storage',
      label: 'Google Storage',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'firestore',
      label: 'Firestore',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'app_engine',
      label: 'App Engine',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'functions_gcp',
      label: 'Functions',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'pubsub',
      label: 'PubSub',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'VMs',
      label: 'VMs',
      subLabel: Model.IAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'VPCs',
      label: 'VPCs',
      subLabel: Model.IAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'gke',
      label: 'GKE',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'dns',
      label: 'DNS',
      subLabel: Model.IAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'PostgreSQL',
      iconName: 'PostgreSQL_name',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'secret_manager',
      label: 'Secret Manager',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
  ],
};

export const dropdownAWS: DropdownProps = {
  header: {
    icon: 'aws-logo',
    label: 'AWS',
    iconCategory: 'supportedComponentsPlatform',
  },
  items: [
    {
      icon: 'EKS',
      label: 'EKS',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'ECS',
      label: 'ECS',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'Lambda',
      label: 'Lambda',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'EC2',
      label: 'EC2',
      subLabel: Model.IAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'aws_vpcs',
      label: 'VPCs',
      subLabel: Model.IAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'S3',
      label: 'S3',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
  ],
};

export const dropdownCommon: DropdownProps = {
  header: {
    icon: 'common',
    label: 'Common',
    iconCategory: 'supportedComponentsPlatform',
  },
  items: [
    {
      icon: 'PostgreSQL',
      iconName: 'PostgreSQL_name',
      subLabel: Model.CAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'ambassador',
      iconName: 'ambassador_name',
      subLabel: Model.CAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'ocelot',
      iconName: 'ocelot_name',
      subLabel: Model.CAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'elastic',
      iconName: 'elastic_name',
      subLabel: Model.CAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'traefik',
      iconName: 'traefik_name',
      subLabel: Model.CAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'prometheus',
      iconName: 'prometheus_name',
      subLabel: Model.CAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'jaeger',
      iconName: 'jaeger_name',
      subLabel: Model.CAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'kafka',
      iconName: 'kafka_name',
      subLabel: Model.CAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'grafana',
      iconName: 'grafana_name',
      subLabel: Model.CAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
  ],
};

export const dropdownOCI: DropdownProps = {
  header: {
    icon: 'oracle',
    label: 'OCI',
    iconCategory: 'supportedComponentsPlatform',
  },
  items: [
    {
      icon: 'OKE',
      label: 'OKE',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'container_instaces',
      label: 'Container Instances',
      subLabel: Model.PAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'oci_VMs',
      label: 'VMs',
      subLabel: Model.IAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
    {
      icon: 'VCN',
      label: 'VCNs',
      subLabel: Model.IAAS,
      iconCategory: 'supportedComponentsPlatform',
    },
  ],
};
