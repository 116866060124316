import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import Image from 'components/atoms/Image/Image';
import FractalJoin from 'components/Carousel/FractalJoin/FractalJoin';
import SupportedComponents from 'components/Carousel/SupportedComponents/SupportedComponents';
import { SupportedComponentsPlatform } from 'components/Carousel/SupportedComponentsPlatform/SupportedComponentsPlatform';
import Layout from 'components/Layout';
import Seo from 'components/SEO';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import 'scss/pages/fractal.scss';
import PricingSection from '../page-components/pricing/PricingSection';

const Fractal = () => {
  const [isFractalJoinOpen, setIsFractalJoinOpen] = useState(false);
  const [fractalJoinPage, setFractalJoinPage] = useState('crowd');
  const [playing, setPlaying] = useState(false);

  const openFractalJoin = (page) => {
    setIsFractalJoinOpen(page !== fractalJoinPage || !isFractalJoinOpen);
    setFractalJoinPage(page);

    const section = document.getElementById('fractal_join');
    if (section !== null) {
      section.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <Layout className="fractal-page">
      <Seo title="Fractal Cloud" />
      <section className="hero-wrapper">
        <div className="hero">
          <div className="hero__content">
            <h1 className="hero__title">
              <Trans>Fractal Cloud</Trans>
            </h1>
            <p className="hero__text">
              <Trans>Define your solution once. Deploy them on any Cloud Vendor.</Trans>
            </p>
          </div>
          <Image name="fractal-page/fractal-hero" className="hero__image" />
        </div>
      </section>

      <section className="product-description">
        <h2 className="product-description__title">
          <Trans>A flexible and extendable Internal Developer Platform</Trans>
        </h2>
        <h3 className="product-description__title">
          <Trans>
            packed with hardened components, automated best-practices, and collaboration features.
          </Trans>
        </h3>
        <p className="product-description__text">
          <Trans>
            Fractal Cloud is a comprehensive solution that supports the entire software development
            lifecycle, from ideation and coding to deployment and maintenance.
          </Trans>
        </p>
        <p className="product-description__text">
          <Trans>
            It empowers developers to work efficiently and effectively, providing them with the
            tools, resources, and infrastructure they need to deliver high-quality software with
            high velocity. Our platform is flexible and adaptable, allowing developers to quickly
            pivot and make changes as needed, without having to navigate through a complex and
            bureaucratic technology landscape.
          </Trans>
        </p>
        <p className="product-description__text">
          <Trans>
            It is secure, easy to use, and designed with collaboration and teamwork in mind, so that
            developers can work together seamlessly, regardless of their location or device.
          </Trans>
        </p>
        <p className="product-description__text">
          <Trans>
            Fractal Cloud streamlines development workflows, eliminates bottlenecks and delays, and
            helps organizations to innovate and deliver value to their customers faster.
          </Trans>
        </p>

        <div className="product-description__video-box--outer">
          <div className="product-description__video-box--inner">
            <button
              className={classNames('product-description__video-button', {
                'product-description__video-button--invisible': playing,
              })}
              onClick={() => setPlaying(true)}
              aria-label="Play Video"
            >
              <div className="product-description__video-play" />
            </button>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=9TESmqOyD4Y"
              height={playing ? '100%' : '0'}
              width={playing ? '100%' : '0'}
              playing={playing}
              controls
              config={{ youtube: { rel: 0, modestbranding: true } }}
              className="product-description__video-player"
            />
          </div>
        </div>
      </section>

      <section className="comparison">
        <h2 className="comparison__title">
          <Trans>Compare Solution</Trans>
        </h2>
        <div className="comparison-table">
          <div className="comparison-table__header">
            <div className="comparison-table__empty-space"></div>
            <div className="comparison-table__header-row">
              <div className="comparison-table__header-item">
                <Image
                  className="comparison-table__header-item-image"
                  name="companies/terraform-logo"
                />
                <div className="comparison-table__header-item-name">
                  <Trans>Terraform</Trans>
                </div>
              </div>
              <div className="comparison-table__header-item">
                <Image
                  className="comparison-table__header-item-image"
                  name="companies/pulumi-logo"
                />
                <div className="comparison-table__header-item-name">
                  <Trans>Pulumi</Trans>
                </div>
              </div>
              <div className="comparison-table__header-item">
                <Image
                  className="comparison-table__header-item-image"
                  name="companies/fractal-logo"
                />
                <div className="comparison-table__header-item-name">
                  <Trans>Fractal</Trans>
                </div>
              </div>
            </div>
          </div>
          <div className="comparison-table__row">
            <span className="comparison-table__row-title">
              <Trans>Multi-cloud Providers</Trans>
            </span>
            <div className="comparison-table__row-checks">
              <Image className="comparison-table__row-image" name="check-mark-green" />
              <Image className="comparison-table__row-image" name="check-mark-green" />
              <Image className="comparison-table__row-image" name="check-mark-green" />
            </div>
          </div>
          <div className="comparison-table__row">
            <span className="comparison-table__row-title">
              <Trans>No Domain Specific Language</Trans>
            </span>
            <div className="comparison-table__row-checks">
              <Image className="comparison-table__row-image" name="cancel-red" />
              <Image className="comparison-table__row-image" name="check-mark-green" />
              <Image className="comparison-table__row-image" name="check-mark-green" />
            </div>
          </div>
          <div className="comparison-table__row">
            <span className="comparison-table__row-title">
              <Trans>No specialized Cloud Infrastrure Staff required</Trans>
            </span>
            <div className="comparison-table__row-checks">
              <Image className="comparison-table__row-image" name="cancel-red" />
              <Image className="comparison-table__row-image" name="cancel-red" />
              <Image className="comparison-table__row-image" name="check-mark-green" />
            </div>
          </div>
          <div className="comparison-table__row">
            <span className="comparison-table__row-title">
              <Trans>Multi-cloud Templates</Trans>
            </span>
            <div className="comparison-table__row-checks">
              <Image className="comparison-table__row-image" name="cancel-red" />
              <Image className="comparison-table__row-image" name="cancel-red" />
              <Image className="comparison-table__row-image" name="check-mark-green" />
            </div>
          </div>
          <div className="comparison-table__row">
            <span className="comparison-table__row-title">
              <Trans>Hardened Components</Trans>
            </span>
            <div className="comparison-table__row-checks">
              <Image className="comparison-table__row-image" name="cancel-red" />
              <Image className="comparison-table__row-image" name="cancel-red" />
              <Image className="comparison-table__row-image" name="check-mark-green" />
            </div>
          </div>
          <div className="comparison-table__row">
            <span className="comparison-table__row-title">
              <Trans>Build and expose custom infrastructure operations</Trans>
            </span>
            <div className="comparison-table__row-checks">
              <Image className="comparison-table__row-image" name="cancel-red" />
              <Image className="comparison-table__row-image" name="cancel-red" />
              <Image className="comparison-table__row-image" name="check-mark-green" />
            </div>
          </div>
          <div className="comparison-table__row">
            <span className="comparison-table__row-title">
              <Trans>Automated components update</Trans>
            </span>
            <div className="comparison-table__row-checks">
              <Image className="comparison-table__row-image" name="cancel-red" />
              <Image className="comparison-table__row-image" name="cancel-red" />
              <Image className="comparison-table__row-image" name="check-mark-green" />
            </div>
          </div>
        </div>
      </section>

      <PricingSection openForm={openFractalJoin} />

      <section id="fractal_join" className="fractal-join">
        <FractalJoin page={fractalJoinPage} isVisible={isFractalJoinOpen} />
      </section>

      <section className="fractal-paths">
        <h2 className="fractal-paths__title">
          <Trans>Thought and built for Enterprise Scale</Trans>
        </h2>
        <p className="fractal-paths__text">
          <Trans>Two approaches available to support your organization in any situation</Trans>
        </p>
        <div className="content-blocks content-blocks--image-background container">
          <div className="content-block">
            <Image className="content-block__image" name="fractal-page/live-system-first" />
            <div className="content-block__info">
              <h3 className="fractal-path__title">
                <Trans>Live-System First</Trans>
              </h3>
              <ul className="fractal-path__list">
                <li className="fractal-path__list-item">
                  <Trans>Focus on the end system for quick roll-outs</Trans>
                </li>
                <li className="fractal-path__list-item">
                  <Trans>Best for PoCs and Moonshots projects</Trans>
                </li>
              </ul>
              <p className="live-system-first__text">
                <Trans>
                  Using the approach we named "Live-System first" you are able to describe the
                  desired state of your infrastructure through code, pretty much as you might
                  already be doing today.
                </Trans>
              </p>
              <p>
                <Trans>
                  However, the difference is that with very limited amount of code you will be able
                  to spawn tailored infrastructure to the need of your application using{' '}
                  <strong>standard and security-hardened components</strong> ready to support your
                  application and services.
                </Trans>
              </p>
              <p>
                <Trans>
                  When you are then <strong>ready for enterprise grade</strong>, you can extract a
                  reusable Blueprint from your running Live System, making it available for your
                  colleagues across your organization, ensuring cross-pollination of proven
                  best-practices, technologies and architectures.
                </Trans>
              </p>
            </div>
          </div>
          <div className="content-block">
            <Image className="content-block__image" name="fractal-page/fractal-first" />
            <div className="content-block__info">
              <h3 className="fractal-path__title">
                <Trans>Fractal First</Trans>
              </h3>
              <ul className="fractal-path__list">
                <li className="fractal-path__list-item">
                  <Trans>Focus on reusability and automation across your organization</Trans>
                </li>
                <li className="fractal-path__list-item">
                  <Trans>
                    Advised on establishing a cloud adoption and governance framework at the
                    enterprise level
                  </Trans>
                </li>
              </ul>
              <p className="fractal-first__text">
                <Trans>
                  Through the approach we named "Fractal first" you{' '}
                  <strong>tackle Cloud-Native complexity</strong> at the enterprise level from the
                  very beginning.
                </Trans>
              </p>
              <p>
                <Trans>
                  You will first define Blueprints, reusable sets of components and their relations,
                  which put the basis for a compliant solutions in your IT Landscape. To each
                  Blueprint you can then associate an Interface, a versioned set of operations
                  through which the Blueprint can be modified{' '}
                  <strong>ensuring the application compliance</strong> remains unaltered.
                </Trans>
              </p>
              <p>
                <Trans>
                  Any teams authorized will then be able to instantiate a specific Blueprint on{' '}
                  <strong>any cloud vendor</strong> you have enabled, resulting in the creation of a
                  Live System they own.
                </Trans>
              </p>
              <p>
                <Trans>
                  The latter will be able to be extended and tailored directly by the team owning it
                  without requiring any access to any actual cloud environment; they will instead
                  use the APIs exposed by the associated Interface.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="supported-components">
        <div className="supported-components__info">
          <h2 className="supported-components__title">
            <Trans>Supported Components</Trans>
          </h2>
          <p className="supported-components__text">
            <Trans>
              With Fractal Cloud you can choose across several components picked among the best in
              the industry at solving the hard parts of Cloud-Native application development. These
              components are security hardened, automatically integrated and ready to be used in
              your production environments.
            </Trans>
          </p>
        </div>
        <SupportedComponents />
      </section>

      <section className="max-w-[1280px] m-auto items-stretch">
        <SupportedComponentsPlatform />
      </section>
    </Layout>
  );
};

export default Fractal;
