import React, { FC, useState, ReactNode } from 'react';
import cn from 'classnames';
import { IconFA } from '../IconFA/IconFA';
import { DropdownProps } from './Dropdown.model';
import Image from '../Image/Image';

const Dropdown: FC<DropdownProps> = ({ header, items }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative w-full inline-block text-left lg:shadow-cardNew rounded-lg">
      <div
        className="flex items-center justify-between lg:pointer-events-none"
        onClick={toggleDropdown}
      >
        <div className="flex items-center p-4">
          {header.icon && (
            <Image name={header.icon} category="supportedComponentsPlatform" className="w-8 h-8" />
          )}

          <span className="text-2xl font-bold ml-4">{header.label}</span>
        </div>

        <div className="lg:hidden">
          <IconFA
            icon={'chevron-right'}
            className={cn('w-5 h-5 transition-transform duration-300 ease-in-out mr-4', {
              'rotate-90': isOpen,
            })}
          />
        </div>
      </div>

      <div
        className={cn('mt-2  w-full transition-all duration-300', {
          'hidden lg:block': !isOpen,
          block: isOpen,
        })}
      >
        <ul className="">
          {items.map((item, index) => (
            <li key={index} className="p-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  {item.icon && (
                    <Image
                      name={item.icon}
                      category="supportedComponentsPlatform"
                      className="w-8 h-8"
                    />
                  )}
                  {item.iconName && (
                    <Image
                      name={item.iconName}
                      category="supportedComponentsPlatform"
                      className="w-20"
                    />
                  )}
                  <span className="ml-2">{item.label}</span>
                </div>
                <span className="text-xs font-medium text-base-300">{item?.subLabel}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
