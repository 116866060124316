import Dropdown from 'components/atoms/Dropdown/Dropdown';
import React from 'react';
import {
  dropdownAWS,
  dropdownAzure,
  dropdownCommon,
  dropdownGCP,
  dropdownOCI,
} from './SupportedComponentsPlatform.model';

export const SupportedComponentsPlatform = () => {
  return (
    <div className="mb-12">
      <div className="w-full flex justify-center mb-[64px]">
        <div className="text-center max-w-[60rem]">
          <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
            Supported Components
          </h2>
          <p className="text-[24px] font-[500] text-base-300 leading-[175%]">
            Maximize your cloud infrastructure's potential with our comprehensive support for a wide
            range of cloud components.{' '}
          </p>
        </div>
      </div>

      <div className="w-full flex justify-center">
        <div className="flex flex-col lg:flex-row w-fit justify-center lg:gap-x-4 shadow-cardNew lg:shadow-0">
          <div className="w-80">
            <Dropdown {...dropdownAzure} />
          </div>
          <div className="flex flex-col w-80 lg:gap-y-6">
            <Dropdown {...dropdownGCP} />
            <Dropdown {...dropdownOCI} />
          </div>
          <div className="flex flex-col w-80 lg:gap-y-6">
            <Dropdown {...dropdownAWS} />
            <Dropdown {...dropdownCommon} />
          </div>
        </div>
      </div>
    </div>
  );
};
